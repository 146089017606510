import React from "react";
import SEO from "../components/SEO";

const NotFoundPage: React.FC = () => {
    return (
        <>
            <SEO title="404: העמוד לא נמצא" />
            <h1>העמוד לא נמצא</h1>
            <p>אל תדאג, בימים הקרובים יהיה מה לראות :)</p>
        </>
    );
};

export default NotFoundPage;
